import { useUserInfo } from '../hooks/useUserInfo';
import { useIsLogin } from '../hooks/isLogin';
import { useNavigate } from "@tanstack/react-location";
import { notifications } from "@mantine/notifications";
import { getkey, returnkey, changekey } from "../utils/key";

export function useGetApikeyByUserid() {
  const { isLogin } = useIsLogin()
  const [userInfo] = useUserInfo()
  const navigate = useNavigate();

  const goLogin = () => {
    navigate({ to: `/` });
    notifications.show({
      title: "Error",
      color: "red",
      message: "请先登录",
    });
  }

  const checkLogin = () => {
    if (!isLogin) {
      goLogin()
      return false
    }
    return true
  }

  const getApiKey = async () => {
    const beforeIf = checkLogin()
    if (beforeIf) {
      const res: any = await getkey(userInfo.userNo, userInfo.mobile, userInfo.token).catch((error) => {
        notifications.show({
          title: "Error",
          color: "red",
          message: error?.message,
        });
        return false
      })
      if (res.code !== 200) {
        notifications.show({
          title: "Error",
          color: "red",
          message: res.message || "系统繁忙，请稍后再试！",
        });
        return false
      }
      if (res.code === 200 && res.data?.apiKey) return res.data.apiKey
    }
    return beforeIf
  }

  const returnApiKey = async () => {
    const res: any = returnkey(userInfo.userNo, userInfo.mobile).catch((error) => {
      notifications.show({
        title: "Error",
        color: "red",
        message: error?.message,
      });
      return false
    })
    return res.code === 200 || false
  }

  const changeApiKey = () => {
    const res: any = changekey(userInfo.userNo, userInfo.mobile, userInfo.token).catch((error) => {
      notifications.show({
        title: "Error",
        color: "red",
        message: error?.message,
      });
      return false
    })
    if (res.code === 201) {
      goLogin()
      return false
    }
    return res.code === 200 || false
  }

  return {
    getApiKey,
    returnApiKey,
    changeApiKey,
  }
}